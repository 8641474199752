import { sample } from 'effector';
import { saveChatHexagonsEv } from './events.js';
import { $chatHexagons } from './stores.js';

sample({
  source: $chatHexagons,
  clock: saveChatHexagonsEv,
  fn: (source, clock) => {
    return {
      ...source,
      [clock.message_index]: clock.map_data,
    };
  },
  target: $chatHexagons,
});
