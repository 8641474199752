import React, { useState, useEffect, useRef } from 'react';
import { Button, Drawer } from 'antd';
import { DoubleLeftOutlined, DoubleRightOutlined } from '@ant-design/icons';
import { useUnit } from 'effector-react';
import { cookies } from '../../api/axiosinstance.js';
import {
  applyChatHexagonsEv,
  saveChatHexagonsEv,
} from '../../models/chatModel/index.js';
import ChatIcon from './ChatIcon.jsx';
import style from './Chat.module.css';
import { $isDarkTheme, $language } from '../../models/authModel/index.js';
import { ru_en_page_dictionary } from '../../dictionaries/ru_en_page_dictionary.js';
// FIXME RCP URL FOR AI ASSISTANT
// const apiUrl = 'https://asst.metatable.ai/create_message'
const apiUrl = `${__API_URL__}chat/`;

function ChatModal() {
  const [messages, setMessages] = useState([]);
  const [input, setInput] = useState('');
  const [threadId, setThreadId] = useState(null);
  const [isTyping, setIsTyping] = useState(false);
  const [open, setOpen] = useState(false);
  const saveChatHexagons = useUnit(saveChatHexagonsEv);
  const applyChatHexagons = useUnit(applyChatHexagonsEv);
  const [animation, setAnimation] = useState(true);
  const language = useUnit($language);
  const isDark = useUnit($isDarkTheme);
  const msgContainer = useRef();
  const authToken = cookies.get('access_token');

  useEffect(() => {
    // Добавляем стартовое сообщение при загрузке виджета
    const initialMessage = {
      text:
        language === 'ru'
          ? `Добро пожаловать!<br/>
      Я ваш виртуальный помощник по оценке жизнестойкости города.
Я помогу вам ориентироваться в методологии, анализировать данные и работать с интерактивной картой.`
          : `Welcome!<br/>
      I am your virtual assistant for assessing urban resilience.
I will help you navigate the methodology, analyze data, and work with the interactive map.`,
      fromUser: false,
    };
    setMessages([initialMessage]);
  }, [language]);
  function processText(input) {
    // Разбиваем текст на строки
    const lines = input.split(/(?<=\\.)\\d+\\.\\s/); // Разделяем по номеру с точкой и пробелом
    const result = [];

    lines.forEach((line) => {
      // Убираем `**` и заменяем содержимое внутри `【】` на пустую строку
      const cleanedLine = line
        .replace(/\\*\\*/g, '')
        .replace(/【[^】]*】/g, '')
        .replace(/\*\*(.*?)\*\*/g, '<b>$1</b>')
        .replace(/(\s\d+\.)/g, '<br/>$1')
        .replace(/(\s-\s)/g, '<br/>$1');

      if (cleanedLine.trim()) {
        result.push(cleanedLine.trim());
      }
    });

    // Возвращаем текст с новой строки для каждого пункта
    return result.join('\\n');
  }

  const sendMessage = async () => {
    if (!input.trim()) return;

    // Отображаем исходящее сообщение
    const newMessages = [...messages, { text: input, fromUser: true }];
    setMessages(newMessages);
    setInput('');
    setIsTyping(true);

    if (msgContainer.current) {
      setTimeout(
        () =>
          (msgContainer.current.scrollTop = msgContainer.current.scrollHeight)
      );
    }

    // Отправляем сообщение в API
    try {
      const response = await fetch(apiUrl, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${authToken}`,
        },
        body: JSON.stringify({
          message: input,
          thread_id: threadId,
        }),
      });
      const data = await response.json();

      // Сохраняем thread_id при первом ответе
      if (!threadId) {
        setThreadId(data.thread_id);
      }

      // Анимация печати входящего сообщения
      animateIncomingMessage(data, newMessages);
    } catch (error) {
      console.error('Ошибка при запросе API:', error);
    } finally {
      setIsTyping(false);
    }
  };

  // Функция для анимации печати текста побуквенно
  const animateIncomingMessage = (data, newMessages) => {
    let index = 0;
    if (data.map_data && Object.keys(data.map_data).length > 0) {
      saveChatHexagons({
        message_index: newMessages.filter((item) => item.map_data).length + 1,
        map_data: data.map_data,
      });
    }
    if (data.message.length > 0) {
      const intervalId = setInterval(() => {
        setMessages([
          ...newMessages,
          {
            text: processText(data.message.slice(0, index + 1)),
            fromUser: false,
            map_data: data.map_data,
            // FIXME allows to recognize which map data to display
            message_index:
              newMessages.filter((item) => item.map_data).length + 1,
          },
        ]);
        index++;
        if (msgContainer.current) {
          msgContainer.current.scrollTop = msgContainer.current.scrollHeight;
        }
        if (index === data.message.length) {
          clearInterval(intervalId);
        }
      }, 5); // Скорость печати в миллисекундах
    } else {
      setMessages([
        ...newMessages,
        {
          text: data.message,
          fromUser: false,
          map_data: data.map_data,
          // FIXME allows to recognize which map data to display
          message_index: newMessages.filter((item) => item.map_data).length + 1,
        },
      ]);
      if (msgContainer.current) {
        msgContainer.current.scrollTop = msgContainer.current.scrollHeight;
      }
    }
  };

  return (
    <>
      <Button
        onClick={() => setOpen(!open)}
        icon={<ChatIcon />}
        shape="circle"
        className={style.chat_button}
        style={{
          animation: animation ? `${style.pulse} 2s infinite` : 'unset',
        }}
        onMouseEnter={() => setAnimation(false)}
      />
      <Drawer open={open} closable={false} mask={false} width={543}>
        <Button
          onClick={() => setOpen(!open)}
          icon={open ? <DoubleRightOutlined /> : <DoubleLeftOutlined />}
          shape="circle"
          style={{
            position: 'absolute',
            left: '-16px',
            top: '50%',
            transform: 'translateY(-50%)',
          }}
        />
        <div
          style={isDark ? styles.chatContainerDark : styles.chatContainer}
          ref={msgContainer}
        >
          <div
            style={
              isDark ? styles.messagesContainerDark : styles.messagesContainer
            }
          >
            {messages.map((msg, index) => (
              <div
                key={index}
                style={{
                  ...styles.message,
                  ...(msg.fromUser
                    ? styles.userMessage
                    : isDark
                      ? styles.apiMessageDark
                      : styles.apiMessage),
                }}
              >
                <p
                  style={styles.messageText}
                  dangerouslySetInnerHTML={{ __html: msg.text }} // Используем dangerouslySetInnerHTML для отображения HTML
                />
                {msg.map_data && Object.keys(msg.map_data).length > 0 && (
                  <Button
                    style={{
                      margin: '10px auto 0 auto',
                      display: 'block',
                    }}
                    onClick={() => applyChatHexagons(msg.message_index)}
                  >
                    Visualize
                  </Button>
                )}
              </div>
            ))}
            {isTyping && (
              <div
                style={
                  isDark ? styles.typingIndicatorDark : styles.typingIndicator
                }
              >
                <p>Typing...</p>
              </div>
            )}
          </div>
          <div
            style={isDark ? styles.inputContainerDark : styles.inputContainer}
          >
            <input
              type="text"
              value={input}
              onChange={(e) => setInput(e.target.value)}
              placeholder={
                ru_en_page_dictionary.chat_input_placeholder[language]
              }
              style={isDark ? styles.inputDark : styles.input}
              onKeyPress={(e) => e.key === 'Enter' && sendMessage()}
            />
            <button onClick={sendMessage} style={styles.sendButton}>
              {ru_en_page_dictionary.chat_send[language]}
            </button>
          </div>
        </div>
      </Drawer>
    </>
  );
}

const styles = {
  chatContainer: {
    width: '100%',
    maxWidth: '850px',
    border: '1px solid #ccc',
    borderRadius: '8px',
    boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)',
    overflow: 'scroll',
    fontFamily: 'Arial, sans-serif',
    maxHeight: '100%',
  },
  chatContainerDark: {
    width: '100%',
    maxWidth: '850px',
    border: '1px solid rgb(43 43 43)',
    borderRadius: '8px',
    boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)',
    overflow: 'scroll',
    fontFamily: 'Arial, sans-serif',
    maxHeight: '100%',
  },
  messagesContainer: {
    maxHeight: 'calc(100% - 79px)',
    overflowY: 'auto',
    padding: '10px',
    backgroundColor: '#f9f9f9',
  },
  messagesContainerDark: {
    maxHeight: 'calc(100% - 79px)',
    overflowY: 'auto',
    padding: '10px',
    backgroundColor: '#181a1f',
  },
  message: {
    marginBottom: '10px',
    padding: '8px 12px',
    borderRadius: '18px',
    maxWidth: '80%',
  },
  userMessage: {
    backgroundColor: '#DCF8C6',
    alignSelf: 'flex-end',
    textAlign: 'right',
    marginLeft: 'auto',
    color: '#333',
  },
  apiMessage: {
    backgroundColor: '#FFF',
    border: '1px solid #e6e6e6',
    color: '#333',
    textAlign: 'left',
  },
  apiMessageDark: {
    backgroundColor: '#1e2328',
    border: '1px solid rgb(61 61 61)',
    color: 'white',
    textAlign: 'left',
  },
  typingIndicator: {
    marginBottom: '10px',
    padding: '8px 12px',
    borderRadius: '18px',
    maxWidth: '80%',
    backgroundColor: '#e6e6e6',
    color: '#666',
    fontStyle: 'italic',
  },
  typingIndicatorDark: {
    marginBottom: '10px',
    padding: '8px 12px',
    borderRadius: '18px',
    maxWidth: '80%',
    backgroundColor: '#1e2328',
    color: 'white',
    fontStyle: 'italic',
  },
  messageText: {
    margin: 0,
    fontSize: '14px',
    lineHeight: '1.4',
  },
  inputContainer: {
    display: 'flex',
    alignItems: 'center',
    padding: '10px',
    borderTop: '1px solid #ddd',
    backgroundColor: '#fff',
  },
  inputContainerDark: {
    display: 'flex',
    alignItems: 'center',
    padding: '10px',
    borderTop: '1px solid rgb(61, 61, 61)',
    backgroundColor: '#1e2328',
  },
  input: {
    flex: 1,
    padding: '10px',
    borderRadius: '18px',
    border: '1px solid #ddd',
    fontSize: '14px',
    outline: 'none',
    marginRight: '8px',
  },
  inputDark: {
    flex: 1,
    padding: '10px',
    borderRadius: '18px',
    border: '1px solid rgb(61 61 61)',
    fontSize: '14px',
    outline: 'none',
    marginRight: '8px',
    backgroundColor: '#1e2328',
    color: 'white',
  },
  sendButton: {
    padding: '8px 12px',
    border: 'none',
    borderRadius: '18px',
    backgroundColor: '#72bf44',
    color: '#fff',
    fontWeight: 'bold',
    cursor: 'pointer',
    transition: 'background-color 0.2s',
  },
  sendButtonHover: {
    backgroundColor: '#0056b3',
  },
};

export default ChatModal;
