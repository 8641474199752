import { Button, Input, Radio, Select, Switch, Table, Tooltip } from 'antd';
import { useUnit } from 'effector-react';
import {
  CheckCircleOutlined,
  CheckOutlined,
  CloseCircleOutlined,
  CloseOutlined,
  EyeOutlined,
  MinusCircleOutlined,
  PlusSquareOutlined,
  QuestionCircleOutlined,
  SyncOutlined,
  UndoOutlined,
} from '@ant-design/icons';
import { useCallback, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import style from './UserList.module.css';
import {
  $isDarkTheme,
  $language,
  $userInfo,
  $userList,
  blockUserFx,
  confirmUserFx,
  getUserListFx,
  makeAdminFx,
  switchUseBackendFx,
  togglePlatformEv,
  togglePlatformFx,
  unblockUserFx,
  $userListDataPresets,
  appointPresetToUserEv,
  toggleUserUsePresetEv,
  toggleUserUsePresetFx,
  getDataPresetsListFx,
} from '../../models/authModel/index.js';
import { ru_en_page_dictionary } from '../../dictionaries/ru_en_page_dictionary.js';

function filterOption(input, option) {
  return (option?.label ?? '').toLowerCase().includes(input.toLowerCase());
}

const noFilterFields = ['perms', 'use_rbp_preset', 'rcp', 'rbp'];

const numberFileds = ['timers'];

const actionFilters = [
  'Заблокированные',
  'Не заблокированные',
  'Не подтвержденные',
  'Подтвержденные',
  'Use Backend',
  'No use backend',
];

const statusFilters = ['Заблокированные', 'Подтвержденные'];

const noSortFields = ['action', 'status'];

export default function UserList() {
  const loading = useUnit(getUserListFx.pending);
  const language = useUnit($language);
  const userList = useUnit($userList);
  // const userInfo = useUnit($userInfo);
  const confirmUser = useUnit(confirmUserFx);
  const confirmUserLoading = useUnit(confirmUserFx.pending);
  const blockUser = useUnit(blockUserFx);
  const blockUserLoading = useUnit(blockUserFx.pending);
  const unblockUser = useUnit(unblockUserFx);
  const unblockUserLoading = useUnit(unblockUserFx.pending);
  const makeAdmin = useUnit(makeAdminFx);
  const makeAdminLoading = useUnit(makeAdminFx.pending);
  const switchUseBackend = useUnit(switchUseBackendFx);
  const switchUseBackendLoading = useUnit(switchUseBackendFx.pending);
  const navigate = useNavigate();
  const darkMode = useUnit($isDarkTheme);
  const togglePlatform = useUnit(togglePlatformEv);
  const togglePlatformLoading = useUnit(togglePlatformFx.pending);
  const userListDataPresets = useUnit($userListDataPresets);
  const toggleUserUsePreset = useUnit(toggleUserUsePresetEv);
  const useUserPresetLoading = useUnit(toggleUserUsePresetFx.pending);
  const appointPresetToUser = useUnit(appointPresetToUserEv);

  const addSortAndFilter = useCallback(
    (field) => {
      let filters = noFilterFields.includes(field)
        ? null
        : Array.from(new Set(userList.map((item) => item[field]))).map(
            (item) => {
              return {
                text: item,
                value: item,
              };
            }
          );
      if (field === 'action') {
        filters = actionFilters.map((item) => {
          return {
            text: item,
            value: item,
          };
        });
      }
      if (field === 'status') {
        filters = statusFilters.map((item) => {
          return {
            text: item,
            value: item,
          };
        });
      }

      return {
        filterSearch: true,
        filters,
        onFilter: (value, record) => {
          if (typeof value === 'object') {
            switch (value.type) {
              case '>':
                return (
                  record[field] >
                  (typeof value.value === 'string'
                    ? Number(value.value)
                    : value.value)
                );
              case '<':
                return (
                  record[field] <
                  (typeof value.value === 'string'
                    ? Number(value.value)
                    : value.value)
                );
              case '=':
                return (
                  record[field] ===
                  (typeof value.value === 'string'
                    ? Number(value.value)
                    : value.value)
                );
            }
          } else {
            if (actionFilters.includes(value)) {
              switch (value) {
                case 'Заблокированные':
                  return record.is_blocked;
                case 'Не заблокированные':
                  return !record.is_blocked;
                case 'Не подтвержденные':
                  return !record.is_confirmed;
                case 'Подтвержденные':
                  return record.is_confirmed;
                case 'Use Backend':
                  return record.use_backend;
                case 'No use backend':
                  return !record.use_backend;
              }
            }
            return record[field] === value;
          }
        },
        sorter: noSortFields.includes(field)
          ? null
          : (a, b) => {
              if (typeof a[field] === 'string') {
                return a[field].localeCompare(b[field]);
              }
              if (typeof a[field] === 'boolean') {
                return b[field] - a[field];
              }
              if (field === 'perms') {
                return b.perms.includes('admin') - a.perms.includes('admin');
              }
              return a[field] - b[field];
            },
        filterDropdown: numberFileds.includes(field)
          ? ({
              setSelectedKeys,
              selectedKeys,
              confirm,
              clearFilters,
              close,
            }) => (
              <div
                style={{
                  padding: 5,
                }}
              >
                <Input
                  value={[selectedKeys[0]?.value]}
                  onChange={(e) =>
                    setSelectedKeys(
                      e.target.value
                        ? [
                            {
                              value: e.target.value,
                              type: selectedKeys[0]?.type,
                            },
                          ]
                        : []
                    )
                  }
                  style={{
                    marginBottom: 5,
                  }}
                />
                <Radio.Group
                  value={selectedKeys[0]?.type || null}
                  onChange={(e) => {
                    setSelectedKeys(
                      e.target.value
                        ? [
                            {
                              value: selectedKeys[0]?.value,
                              type: e.target.value,
                            },
                          ]
                        : []
                    );
                  }}
                >
                  <Radio value=">">{'>'}</Radio>
                  <Radio value="<">{'<'}</Radio>
                  <Radio value="=">=</Radio>
                </Radio.Group>
                <div
                  style={{
                    display: 'flex',
                    columnGap: 10,
                    justifyContent: 'flex-end',
                  }}
                >
                  <Button
                    onClick={() => {
                      if (selectedKeys[0].value && selectedKeys[0].type) {
                        confirm();
                      }
                      console.log('selectedKeys', selectedKeys);
                    }}
                    type="primary"
                  >
                    OK
                  </Button>
                  <Button
                    onClick={() => {
                      clearFilters();
                      setSelectedKeys([]);
                      confirm();
                    }}
                  >
                    Reset
                  </Button>
                </div>
              </div>
            )
          : null,
      };
    },
    [userList]
  );

  const columns = [
    {
      title: ru_en_page_dictionary.table_name[language],
      dataIndex: 'name',
      key: 'name',
      ...addSortAndFilter('name'),
    },
    {
      title: ru_en_page_dictionary.table_email[language],
      dataIndex: 'email',
      key: 'email',
      ...addSortAndFilter('email'),
    },
    {
      title: ru_en_page_dictionary.table_organization[language],
      dataIndex: 'organization',
      key: 'organization',
      ...addSortAndFilter('organization'),
    },
    // {
    //   title: ru_en_page_dictionary.table_performance[language],
    //   dataIndex: '',
    //   key: 'performance',
    //   render: (text, record) => {
    //     return (
    //       <>
    //         <div>
    //           {ru_en_page_dictionary.table_download[language]}:{record.download}
    //         </div>
    //         <br />
    //         <div>
    //           {ru_en_page_dictionary.table_mutate[language]}: {record.mutate}
    //         </div>
    //       </>
    //     );
    //   },
    // },
    {
      title: ru_en_page_dictionary.table_admin[language],
      dataIndex: '',
      key: 'is_admin',
      ...addSortAndFilter('perms'),
      render: (text, record) => {
        return (
          <Switch
            checked={record.perms.includes('admin')}
            onChange={(checked) => {
              makeAdmin({
                id: record.id,
                appoint: checked,
              });
            }}
            loading={makeAdminLoading}
          />
        );
      },
    },
    {
      title: 'RCP',
      dataIndex: '',
      key: 'user-rcp',
      ...addSortAndFilter('rcp'),
      render: (_, record) => {
        return (
          <Tooltip title={ru_en_page_dictionary.toggle_rcp[language]}>
            <Switch
              style={{ marginRight: '10px' }}
              checked={record.rcp}
              onChange={(checked) =>
                togglePlatform({
                  id: record.id,
                  payload: {
                    rcp: checked,
                  },
                })
              }
              loading={togglePlatformLoading}
            />
          </Tooltip>
        );
      },
    },
    {
      title: 'RBP',
      dataIndex: '',
      key: 'user-rbp',
      ...addSortAndFilter('rbp'),
      render: (_, record) => {
        return (
          <Tooltip title={ru_en_page_dictionary.toggle_rbp[language]}>
            <Switch
              style={{ marginRight: '10px' }}
              checked={record.rbp}
              onChange={(checked) =>
                togglePlatform({
                  id: record.id,
                  payload: {
                    rbp: checked,
                  },
                })
              }
              loading={togglePlatformLoading}
            />
          </Tooltip>
        );
      },
    },
    {
      title: ru_en_page_dictionary.appoint_preset[language],
      dataIndex: '',
      key: 'user-use_rbp_preset',
      ...addSortAndFilter('use_rbp_preset'),
      render: (_, record) => {
        return (
          <Tooltip title={ru_en_page_dictionary.appoint_preset[language]}>
            <Switch
              style={{ marginRight: '10px' }}
              checked={record.use_rbp_preset}
              onChange={(checked) =>
                toggleUserUsePreset({
                  id: record.id,
                  payload: {
                    use_rbp_preset: checked,
                  },
                })
              }
              loading={useUserPresetLoading}
            />
          </Tooltip>
        );
      },
    },
    {
      title: ru_en_page_dictionary.presets[language],
      dataIndex: '',
      width: 250,
      render: (_, record) => {
        return (
          <Select
            style={{ width: '200px' }}
            options={userListDataPresets}
            disabled={!record.use_rbp_preset}
            mode="multiple"
            allowClear
            showSearch
            filterOption={filterOption}
            defaultValue={record.presets.rbp.map((item) => item.id)}
            onChange={(value) => {
              appointPresetToUser({
                user_id: record.id,
                presets: value.map((id) => {
                  return {
                    preset_id: id,
                    service: 'rbp',
                  };
                }),
                // service: 'rbp',
              });
            }}
          />
        );
      },
    },
    {
      title: ru_en_page_dictionary.table_timers[language],
      dataIndex: 'timers',
      key: 'timers',
      ...addSortAndFilter('timers'),
    },
    {
      title: ru_en_page_dictionary.table_status[language],
      dataIndex: '',
      key: 'status',
      ...addSortAndFilter('status'),
      render: (text, record) => {
        if (record.is_blocked) {
          return (
            <Tooltip title={ru_en_page_dictionary.status_blocked[language]}>
              <CloseCircleOutlined style={{ color: 'red' }} />
            </Tooltip>
          );
        }
        if (record.is_confirmed) {
          return (
            <Tooltip title={ru_en_page_dictionary.status_confirmed[language]}>
              <CheckCircleOutlined style={{ color: 'green' }} />
            </Tooltip>
          );
        }
        return (
          <Tooltip title={ru_en_page_dictionary.status_undefined[language]}>
            <QuestionCircleOutlined style={{ color: 'grey' }} />
          </Tooltip>
        );
      },
    },
    {
      title: ru_en_page_dictionary.table_action[language],
      dataIndex: '',
      key: 'action',
      ...addSortAndFilter('action'),
      render: (text, record) => {
        return (
          <div className={style.actions_wrapper}>
            {record.is_blocked ? (
              <Tooltip
                title={ru_en_page_dictionary.table_action_unblock[language]}
              >
                <Button
                  className={style.action_button}
                  icon={<UndoOutlined />}
                  onClick={() => unblockUser(record.id)}
                  loading={unblockUserLoading}
                />
              </Tooltip>
            ) : (
              <Tooltip
                title={ru_en_page_dictionary.table_action_block[language]}
              >
                <Button
                  className={style.action_button}
                  icon={<CloseOutlined />}
                  onClick={() => blockUser(record.id)}
                  loading={blockUserLoading}
                />
              </Tooltip>
            )}
            {!record.is_confirmed ? (
              <Tooltip
                title={ru_en_page_dictionary.table_action_confirm[language]}
              >
                <Button
                  className={style.action_button}
                  icon={<CheckOutlined />}
                  onClick={() => confirmUser(record.id)}
                  loading={confirmUserLoading}
                />
              </Tooltip>
            ) : (
              ''
            )}
            <Tooltip
              title={`${ru_en_page_dictionary.table_switch[language]} ${
                record.use_backend
                  ? ru_en_page_dictionary.table_to_front[language]
                  : ru_en_page_dictionary.table_to_back[language]
              }`}
            >
              <Switch
                style={{ marginRight: '10px' }}
                checked={record.use_backend}
                onChange={(checked) =>
                  switchUseBackend({
                    id: record.id,
                    useBackend: checked,
                  })
                }
                loading={switchUseBackendLoading}
              />
            </Tooltip>
            <Tooltip title={ru_en_page_dictionary.table_action_view[language]}>
              <Link to={`/user/${record.id}`} target="_blank">
                <Button
                  className={style.action_button}
                  icon={<EyeOutlined />}
                />
              </Link>
            </Tooltip>
          </div>
        );
      },
    },
  ];

  // useEffect(() => {
  //   if (Object.keys(userInfo).length > 0) {
  //     if (!userInfo.perms.includes('admin')) {
  //       navigate('/root');
  //     }
  //   }
  // }, [userInfo]);

  useEffect(() => {
    getDataPresetsListFx();
  }, []);

  return (
    <div>
      <div
        className={style.user_list_tilte}
        style={{ color: darkMode ? 'white' : 'black' }}
      >
        {ru_en_page_dictionary.user_list_title[language]}
      </div>
      <Table
        className={style.user_list_table}
        loading={loading}
        columns={columns}
        dataSource={userList}
        scroll={{
          y: 800,
        }}
        pagination={false}
      />
    </div>
  );
}
