import { Card, Col, Row, Space } from 'antd';
import { useUnit } from 'effector-react';
import style from './MainPageRestyle.module.css';
import LeftBar from '../../components/LeftBar/LeftBar.jsx';
import MapBlock from '../../components/MapBlock/MapBlock.jsx';
import TopBar from '../../components/TopBar/TopBar.jsx';
import SideModal from '../../components/SideModal/SideModal.jsx';
import { $isTablet, $tabletBarHidden } from '../../models/authModel/index.js';
import TabletLeftBar from '../../components/TabletLeftBar/TabletLeftBar.jsx';
import PresetsSideModal from '../../components/PresetsSideModal/PresetsSideModal.jsx';
import { $mapLoaded } from '../../models/mapModel/index.js';
import ChatModal2 from '../ChatModal/ChatModal2.jsx';

export default function MainPageRestyle2() {
  const isTablet = useUnit($isTablet);
  const tabletBarHidden = useUnit($tabletBarHidden);
  const mapLoaded = useUnit($mapLoaded);
  if (isTablet) {
    return (
      <Row gutter={[5, 0]} style={{ padding: '5px 0px', height: '100%' }}>
        <Col
          style={{
            width: tabletBarHidden ? '86px' : '385px',
            maxHeight: '100%',
            transition: '0.5s all',
          }}
        >
          <TabletLeftBar />
        </Col>
        <Col
          style={{
            width: tabletBarHidden ? 'calc(100% - 86px)' : 'calc(100% - 385px)',
            transition: '0.5s all',
          }}
        >
          <Card className="map_card" bodyStyle={{ height: '100%' }}>
            <MapBlock />
          </Card>
        </Col>
      </Row>
    );
  }
  return (
    <Row gutter={[20, 0]} style={{ height: '100%' }}>
      <Col style={{ width: '400px' }}>
        <LeftBar />
      </Col>
      <Col style={{ width: 'calc(100% - 400px)' }}>
        <div className={style.test}>
          <TopBar />
          <Card className="map_card" bodyStyle={{ height: '100%' }}>
            <MapBlock />
          </Card>
        </div>
        {/* <Space id="top_bar" className={style.top_bar} direction="vertical"> */}
        {/* </Space> */}
      </Col>
      <SideModal />
      <PresetsSideModal />
      <ChatModal2 />
    </Row>
  );
}
